<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="REGULATION_BILL_API_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="regulationBillCd"
            label="규제법규"
            v-model="searchParam.regulationBillCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="규제항목 목록"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :selection="popupParam.type"
      rowKey="regulatedItemCd"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'regulPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
        regulationBillCd: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'regulationBillCd', }
        ],
        columns: [
          {
            name: 'regulationBillName',
            field: 'regulationBillName',
            label: '규제법규',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regulatedItemCd',
            field: 'regulatedItemCd',
            label: '규제항목 코드',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regulatedItemName',
            field: 'regulatedItemName',
            label: '규제항목 명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.regulation.item.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
